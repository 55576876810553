import { Link, useLocation } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Fragment } from 'react';
import { IoMdHome } from 'react-icons/io';
import { getBreadcrumbFromPath } from '@/lib/utils';

export default function AppBreadcrumb() {
  const { pathname } = useLocation();
  const breadcrumbs = pathname.split('/').slice(1);
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <Link to="/dashboard">
            <BreadcrumbLink asChild>
              <IoMdHome />
            </BreadcrumbLink>
          </Link>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        {breadcrumbs.slice(0, -1).map((breadcrumb) => (
          <Fragment key={breadcrumb}>
            <BreadcrumbItem className="text-xs font-medium">
              <Link to={'/' + breadcrumb}>{getBreadcrumbFromPath(breadcrumb)}</Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
          </Fragment>
        ))}

        <BreadcrumbItem>
          <BreadcrumbPage className="text-azura-blue-dark text-xs font-semibold">
            {getBreadcrumbFromPath(breadcrumbs.at(-1) ?? '')}
          </BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
}
