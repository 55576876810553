import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Converts a path string into a breadcrumb string by splitting the path by dashes,
 * capitalizing each part, and joining them back together with spaces.
 *
 * @param {string} path - The path string to convert.
 * @returns {string} The breadcrumb string.
 */
export const getBreadcrumbFromPath = (path: string) =>
  path.split("-").map(capitalize).join(" ");
