export const convertToLocalTime = (date: string) => {
  return Intl.DateTimeFormat('ma-MA', {
    timeZone: 'Africa/Casablanca',
    minute: '2-digit',
    hour: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(date));
};
