import * as React from 'react';

import { cn } from '@/lib/utils';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  ring?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ring = true, ...props }, ref) => {
  const [typeState, setTypeState] = React.useState(type);
  const ringClass =
    'ring-offset-background focus-visible:ring-1 focus-visible:ring-azura-blue focus-visible:ring-offset-2';

  return (
    <div className="relative w-full">
      <input
        type={typeState}
        className={cn(
          'flex h-10 w-full rounded-md border border-input focus-visible:outline-none bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus-visible:border-none focus-visible:ring-azura-blue',
          ring && ringClass,
          className,
        )}
        ref={ref}
        {...props}
      />
      {type === 'password' && (
        <button
          type="button"
          onClick={() => setTypeState(typeState === 'password' ? 'text' : 'password')}
          className="absolute bottom-0 right-0 flex h-full items-center justify-center px-3"
        >
          {typeState === 'password' ? <FaEye className="text-zinc-400" /> : <FaEyeSlash className="text-zinc-400" />}
        </button>
      )}
    </div>
  );
});
Input.displayName = 'Input';

export { Input };
