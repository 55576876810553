import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { useNotification } from '@/hooks/useNotification';
import { convertToLocalTime } from '@/lib/date';
import { NotificationType } from '@/types/notification';
import { LucideAlertCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { FaGear } from 'react-icons/fa6';
import { HiOutlineBoltSlash } from 'react-icons/hi2';
import { MdOutlineNotificationsActive, MdSensorsOff } from 'react-icons/md';
import { Link } from 'react-router-dom';

const OPEN_NOTIFICATIONS_COMMAND = 'm';

export function Notification() {
  const [open, setOpen] = useState(false);
  const [newNotification, setNewNotification] = useState(false);

  const newNotificationCallback = () => setNewNotification(true);

  const { data: notifications } = useNotification(newNotificationCallback);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === OPEN_NOTIFICATIONS_COMMAND && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setOpen((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);
  return (
    <Sheet
      open={open}
      onOpenChange={(v) => {
        setOpen(v);
        setNewNotification(false);
      }}
    >
      <SheetTrigger asChild className="relative">
        <Button
          variant="outline"
          size="icon"
          className="focus-visible:ring-0 focus-visible:outline-none focus-visible:ring-offset-0 rounded-full"
        >
          {newNotification && <span className="absolute size-2 rounded-full bg-red-600 right-0 top-1 z-50"></span>}
          <MdOutlineNotificationsActive />
        </Button>
      </SheetTrigger>
      <SheetContent className="p-0 outline-none overflow-y-auto" hideCloseButton>
        <SheetHeader className="mb-2 px-4 py-3 flex-row items-center justify-between space-y-0">
          <SheetTitle>Notifications</SheetTitle>
          <Link
            to="/settings/notifications"
            className="text-slate-600 hover:text-slate-700"
            onClick={() => setOpen(false)}
          >
            <FaGear />
          </Link>

          <SheetDescription className="hidden">Notifications</SheetDescription>
        </SheetHeader>
        <div className="flex flex-col divide-y">
          {notifications && notifications.length > 0 ? (
            <>
              {notifications.map((notif) => {
                let title = '';
                if (notif.type == 'machine_pressure') {
                  title = `la pression de la machine ${notif.machine_id?.name} atteint son seuil`;
                }
                if (notif.type == 'zone_status') {
                  title = `la zone ${notif.zone_id?.name} eu une coupure électrique`;
                }
                if (notif.type == 'machine_status') {
                  title = `la machine ${notif.machine_id?.name} est en êtat de defaut`;
                }
                return <NotificationItem key={notif._id} date={notif.created_at} title={title} status={notif.type} />;
              })}
            </>
          ) : (
            <p className="text-xs text-muted-foreground px-4">Aucune notification</p>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
}
function NotificationItem({ status, date, title }: { status: NotificationType; date: string; title: string }) {
  const NotifIcon =
    status === 'zone_status' ? HiOutlineBoltSlash : status === 'machine_pressure' ? LucideAlertCircle : MdSensorsOff;
  return (
    <div className="grid grid-cols-[auto_1fr] py-4 gap-3 hover:bg-gray-100 rounded-md cursor-default px-4">
      <div className="p-2 rounded-md bg-gray-100 grid place-items-center">
        <NotifIcon className="text-red-500 size-6" />
      </div>
      <div>
        <div className="flex flex-col text-sm gap-1 justify-start items-start">
          <span className="text-red-500">{title}</span>
          <span className="text-xs text-muted-foreground">{convertToLocalTime(date)}</span>
        </div>
      </div>
    </div>
  );
}
