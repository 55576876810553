import loadable from '@/lib/loadable';
import AuthMiddleware from '@/middlewares/AuthMiddleware';
import GuestMiddleware from '@/middlewares/GuestMiddleware';
import { wrapCreateBrowserRouterV6 } from '@sentry/react';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';

//* auth
const SignIn = loadable(() => import('@/pages/auth/SignIn'), 'h-screen');

//* dashboard
const Dashboard = loadable(() => import('@/pages/dashboard'));

//* Machines management
const Machines = loadable(() => import('@/pages/machines/index'));
const AddMachine = loadable(() => import('@/pages/machines/add-machine/AddMachine'));
const SingleMachine = loadable(() => import('@/pages/machines/single-machine/index'));
//* Capteurs management
const Capteurs = loadable(() => import('@/pages/capteurs/index'));

// * Settings
const Settings = loadable(() => import('@/pages/settings'));
const Account = loadable(() => import('@/pages/settings/account'));
const Security = loadable(() => import('@/pages/settings/security'));
const Notifications = loadable(() => import('@/pages/settings/notifications'));

//* Not found
const NotFound = loadable(() => import('@/components/app/NotFound'));

//* Server error
const ErrorBoundary = loadable(() => import('@/routes/ErrorBoundary'));

const sentryCreateBrowserRouter = wrapCreateBrowserRouterV6(createBrowserRouter);

const routes: RouteObject[] = [
  {
    path: '/auth',
    element: <GuestMiddleware />,
    children: [
      {
        path: 'sign-in',
        element: <SignIn />,
      },
    ],
  },
  {
    path: '/',
    ErrorBoundary,
    element: <AuthMiddleware />,
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/machines',
        element: <Machines />,
      },
      {
        path: '/machines/add',
        element: <AddMachine />,
      },
      {
        path: '/machines/:id',
        element: <SingleMachine />,
      },
      {
        path: '/capteurs',
        element: <Capteurs />,
      },
      {
        path: '/settings',
        element: <Settings />,
        children: [
          {
            path: '/settings/account',
            element: <Account />,
          },
          {
            path: '/settings/security',
            element: <Security />,
          },
          {
            path: '/settings/notifications',
            element: <Notifications />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: (
      <div className="h-screen flex items-center justify-center">
        <NotFound />
      </div>
    ),
  },
];

const router = sentryCreateBrowserRouter(routes);

export default router;
