import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  User,
} from "firebase/auth";
import { auth } from "./firebase";

export const register = async (email: string, password: string) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

export const login = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const logout = async () => {
  return await signOut(auth);
};

export async function changeUserPassword(
  user: User,
  currentPassword: string,
  newPassword: string
) {
  if (!user) {
    throw new Error("No user is signed in");
  }

  if (!user.email) {
    throw new Error("User email is not available");
  }

  // Step 1: Re-authenticate the user if needed
  const credential = EmailAuthProvider.credential(user.email, currentPassword);

  await reauthenticateWithCredential(user, credential);

  // Step 2: Update the password
  await updatePassword(user, newPassword);
}
