import axios from '@/lib/axios';
import { Notification, NotificationEmail } from '@/types/notification';

export const getNotification = async (): Promise<Notification[]> => {
  return (await axios.get('/notification/Notifications')).data.notifications;
};

export const allNotificationEmails = async (): Promise<NotificationEmail[]> => {
  return (await axios.get('/notification/allEmails')).data.emails;
};

export const updateNotifiedEmails = async (emails: string[], id: string) => {
  return await axios.post(`/notification/updateEmails/${id}`, { emails });
};
