import axiosInstance from 'axios';
import { API_URL } from './config';
import { auth } from '@/firebase/firebase';

const axios = axiosInstance.create({
  baseURL: API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

axios.interceptors.response.use(
  (response: any) => response,
  (error: { response: { data: any } }) => {
    if (error.response.data.message === 'Invalid token.') {
      auth.currentUser?.getIdTokenResult().then((idTokenResult) => {
        axios.defaults.headers.common.Authorization = `Bearer ${idTokenResult.token}`;
      });
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  },
);
export default axios;
