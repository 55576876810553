import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/components/ui/sidebar';
import SidebarImage from '@/components/app/SidebarImage';

import { Link, useLocation } from 'react-router-dom';

import { MdOutlineSensors, MdSettings, MdSpaceDashboard } from 'react-icons/md';
import { RxDashboard } from 'react-icons/rx';

// Menu items.
const items = [
  {
    title: 'Tableau de bord',
    url: '/dashboard',
    icon: MdSpaceDashboard,
  },
  {
    title: 'Gestion des machines',
    url: '/machines',
    icon: RxDashboard,
  },
  {
    title: 'Gestion des capteurs',
    url: '/capteurs',
    icon: MdOutlineSensors,
  },
  {
    title: 'Paramètres',
    url: '/settings',
    icon: MdSettings,
  },
];

export default function AppSidebar() {
  const { pathname } = useLocation();
  const currentItem = pathname.split('/')[1];
  return (
    <Sidebar collapsible="icon">
      <SidebarHeader className="items-center">
        <SidebarImage />
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {items.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton tooltip={item.title} asChild isActive={currentItem === item.url.split('/')[1]}>
                    <Link to={item.url}>
                      <item.icon />
                      <span>{item.title}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
