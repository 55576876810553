import { Navigate, Outlet, useLocation } from 'react-router-dom';

import Sidebar from '@/components/app/Sidebar';
import { SidebarProvider, SidebarTrigger, useSidebar } from '@/components/ui/sidebar';

import { useAuth } from '@/hooks/useAuth';
import { Separator } from '@/components/ui/separator';
import AppBreadcrumb from '@/components/app/AppBreadcrumb';
import { Notification } from '@/components/app/Notification';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SocketContextProvider from '@/contexts/SocketContext';
import { Toaster } from '@/components/ui/toaster';
import { UserMenu } from '@/components/app/UserMenu';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
    },
  },
});

export default function AuthMiddleware() {
  const location = useLocation();
  const { isAuthenticated, loading } = useAuth();

  if (!isAuthenticated && !loading) {
    return <Navigate state={{ from: location }} to="/auth/sign-in" />;
  }

  return (
    <SidebarProvider>
      <SocketContextProvider>
        <QueryClientProvider client={queryClient}>
          <Sidebar />
          <Layout>
            <header className="px-12 py-3 flex justify-between items-center">
              <div className="flex h-5 items-center gap-x-3 relative">
                <SidebarTrigger className="md:absolute md:-left-12" />
                <Separator orientation="vertical" />
                <AppBreadcrumb />
              </div>
              <div className="flex items-center gap-x-3">
                <Notification />
                <UserMenu />
              </div>
            </header>
            <section className="border h-full">
              <Outlet />
              <Toaster />
            </section>
          </Layout>
          <ReactQueryDevtools buttonPosition="bottom-left" />
        </QueryClientProvider>
      </SocketContextProvider>
    </SidebarProvider>
  );
}

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { open } = useSidebar();
  const sidebarWidth = open ? '17rem' : '3rem';
  return <main className={`flex flex-col flex-1 md:max-w-[calc(100vw-${sidebarWidth})]`}>{children}</main>;
};
